.Hero.FullBleedHero {
	.BackgroundImage .Heading-title::after {
		display: none;
	}

	.FullBleedHero-heading {
		.FullBleedHero-breadcrumb {
			display: inline-block;
			margin: 0 0 4px;
			text-decoration: none;
			text-transform: none;
			border: 0;
		}

		text-align: center;
	}
}
