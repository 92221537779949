.SearchBar--brandhub {
	@include rtl {
		direction: rtl;
	}

	.SearchBar {
		&-table-row {
			cursor: pointer;
		}

		&-row {
			margin-top: 42px;

			@include rtl {
				direction: rtl;
				margin-top: 42px;
			}
		}

		&-button {
			border-left: solid;
			border-width: thin;
			border-left-color: grey;
			padding-left: 15px;
		}

		&-table-container {
			margin-bottom: 10px;
			overflow: auto;
		}

		&-table-heading {
			@include sansBold();
			font-size: 0.75rem;
			letter-spacing: 0.25em;
			line-height: 1.33333em;
			text-transform: uppercase;
		}
	}

	.ItalicText-text {
		text-align: right;

		@include rtl {
			text-align: left;
		}
	}

	.formElement-label {
		@include rtl {
			text-align: right;
		}
	}

	.formElement-field {
		width: 100%;
		height: 46px;
		border-radius: 0;
		border: none;
		border-bottom-color: currentColor;
		border-bottom-style: none;
		border-bottom-width: medium;
		padding: 0.5em 0;
		padding-right: 0;
		color: #000;
		background: #fff;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.formElement-label {
		font-weight: bold;
		letter-spacing: 0.15em;
	}

	.ItalicText-text {
		@include sansBold();
		font-size: small;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 0;
	}

	.greyBackground {
		opacity: 0.6;
		filter: alpha(opacity = 60);
	}

	.highlight-name {
		color: #fff2a8;
	}

	.Dropdown-field {
		background: #f5f5f5;
	}

	.TextInput-field {
		background: #f5f5f5;
	}

	.TextInput {
		top: -15px;
	}

	.Dropdown {
		top: -15px;
	}
}
