.StyleguideTable {
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	color: #000;
	padding-top: 20px;
	padding-bottom: 50px;

	tr {
		text-align: left;

		td,
		th {
			border-bottom: 1px solid $black;
			text-transform: capitalize;
			padding-top: 20px;
			padding-bottom: 20px;
		}
		th {
			text-transform: uppercase;
			font-weight: normal;
		}
	}
}
