.Tabs {
	background-color: $white;

	&-buttons {
		overflow: hidden;
		border-bottom: 1px solid $black;
		background-color: inherit;
		width: auto;
		text-align: center;
		font-family: Neue Helvetica, Helvetica, sans-serif;

		&-button {
			background-color: inherit;
			border: none;
			outline: none;
			cursor: pointer;
			padding: 30px 35px;
			font-size: 16px;
			line-height: 24px;
			margin-left: 5px;
			margin-right: 5px;
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: 4px;
			color: $gray2;

			&:hover {
				background-color: inherit;
				color: $black;
				border-bottom: 3px solid $black;
			}
			&.active {
				background-color: inherit;
				border-bottom: 3px solid $black;
				color: $black;
			}
		}
	}
	&-content {
		display: none;
		padding: 6px 12px;
		border-top: none;
		min-height: 300px;
		margin-left: 0;
		margin-right: 0;
		margin-top: 0;
		margin-bottom: 0;

		&:first-child {
			display: block;
		}

		@include viewport(medium) {
			margin-left: 75px;
			margin-right: 75px;
		}
	}
}
