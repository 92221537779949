.PageFooter--brandhub {
	.TableList {
		margin-bottom: 113px;

		@include viewport(medium) {
			margin-bottom: 100px;
		}

		@include viewport(xlarge) {
			margin-bottom: 140px;
		}
	}
}
