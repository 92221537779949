.BrandhubComponentContainer {
	background-color: $black;
	width: 100%;

	&-frameContainer {
		overflow-x: hidden;
		overflow-y: hidden;
		background-color: $gray4;
		display: none;

		@include viewport(medium) {
			display: block;
		}
	}

	&-frame {
		border: none;
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 1420px;
		width: 100%;
	}

	&-tags {
		min-height: 50px;
		color: $gray2;
		background-color: $gray4;
		font-size: 14px;
		padding-left: 75px;
		line-height: 90px;
		display: none;
		text-transform: none;

		@include viewport(medium) {
			display: block;
		}

		span {
			line-height: 50px;

			ul {
				display: inline-block;

				li {
					display: inline-block;
					font-size: 16px;
					letter-spacing: 0.5px;
					line-height: 24px;
					font-weight: lighter;
					color: $gray4;
					background-color: $gray2;
					height: 40px;
					margin-right: 15px;
					padding: 8px;
					font-weight: 400;
				}
			}
		}
	}

	&-themeSwitches {
		width: 350px;
		text-align: right;
		display: none;

		@include viewport(medium) {
			display: table-cell;
		}

		button {
			height: 40px;
			padding: 8px;
			letter-spacing: 0.5px;
			line-height: 24px;
			font-style: normal;
			font-size: 14px;
			font-weight: lighter;
			text-transform: none;
			border: 1px solid $black;
			margin: 0;
			min-width: 65px;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			cursor: pointer;
			display: -webkit-inline-box;
			display: -ms-inline-flexbox;
			display: inline-flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			text-align: center;
			text-decoration: none;
			-webkit-transition: none;
			transition: none;
			background-color: $gray4;
			color: $black;
			font-weight: 400;
		}
	}
	&-themeSwitches--dark {
		&.selected {
			background-color: $black;
			border: 1px solid $black;
			color: $gray4;
			cursor: default;
		}
	}
	&-themeSwitches--light {
		&.selected {
			background-color: $black;
			border: 1px solid $black;
			color: $gray4;
			cursor: default;
		}
	}

	&-sizeSwitches-dimensions {
		color: $gray3;
		font-size: 12px;
	}

	&-sizeSwitches {
		width: 350px;
		line-height: 18px;
		letter-spacing: 0.5px;
		font-weight: light;
		font-size: 12px;
		padding: 20px 0;
		display: none;

		@include viewport(medium) {
			display: table-cell;
		}

		ul li {
			float: left;
			width: 50px;
			position: relative;
			top: 15px;
		}
		.icon {
			margin-bottom: 15px;
			cursor: pointer;
			padding: 2px;
			height: 50px;
			border: 0.5px solid $gray3;
			background-color: $gray3;
			margin-right: 5px;
			color: $gray2;
			font-size: 40px;

			hr {
				display: block;
				width: 20px;
				color: $gray3;
			}

			&.selected {
				color: $black;

				hr {
					display: block;
					color: $black;
				}
			}
			&::before {
				position: relative;
			}
			&.desktop {
				&::before {
					left: 3px;
					top: 15px;

					@include icon('icon-desktop');
				}
			}
			&.tablet {
				&::before {
					left: 3px;
					top: 15px;

					@include icon('icon-tablet');
				}
			}
			&.mobile {
				&::before {
					top: 15px;
					left: 3px;

					@include icon('icon-mobile');
				}
			}
			&:hover {
				color: $black;
			}
		}
	}

	&-controlPanel {
		background-color: $gray4;
		height: 75px;
	}

	&-framework {
		margin-left: auto;
		margin-right: auto;

		.Heading {
			margin-left: 25px;
			margin-right: 25px;
			color: $black;
			text-align: center;
			letter-spacing: 0.16667em;
			line-height: 1.29167em;
			font-size: 2rem;
		}
	}

	&.desktop .BrandhubComponentContainer-framework,
	&.desktop .BrandhubComponentContainer-frameContainer,
	&.desktop .BrandhubComponentContainer-frame,
	&.desktop table.BrandhubComponentContainer-controlPanel {
		max-width: 1420px;
		width: 100%;
	}
	&.tablet .BrandhubComponentContainer-framework,
	&.tablet .BrandhubComponentContainer-frameContainer,
	&.tablet .BrandhubComponentContainer-frame,
	&.tablet table.BrandhubComponentContainer-controlPanel {
		max-width: 768px;
		width: 100%;
	}
	&.mobile .BrandhubComponentContainer-framework,
	&.mobile .BrandhubComponentContainer-frameContainer,
	&.mobile .BrandhubComponentContainer-frame,
	&.mobile table.BrandhubComponentContainer-controlPanel {
		max-width: 375px;
		width: 100%;
	}
	&.desktop .BrandhubComponentContainer-frame {
		height: 600px;
	}
	&.tablet .BrandhubComponentContainer-frame {
		height: 425px;
	}
	&.mobile .BrandhubComponentContainer-frame {
		height: 490px;
	}
}
