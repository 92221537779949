.signin-body {
	background: $white;

	.signin-form {
		text-align: center;
		margin-top: 20px;
		background: $white;
		padding: 20px 40px 40px;

		@include viewport(medium) {
			margin-top: 60px;
		}
	}

	.signin-logo {
		&::before {
			@include icon('icon-tree');
			display: inline-block;
			line-height: 1;
			font-size: rem(72, 16);
		}
	}

	.signin-login {
		margin-top: 50px;
	}

	.signin-button {
		@include button('primary', 'light');
		border-radius: 0;
		appearance: none;

		&:disabled {
			background: $gray2;
			cursor: default;
			border: 1px solid $gray2;

			&:hover {
				color: $white;
			}
		}
	}

	.signin-error {
		color: $error-red;
	}

	.filmacademy {
		.nowrap {
			white-space: nowrap;
		}

		.ty-h3 {
			font-size: 0.9rem;
		}

		.ty-h3.signin-extra-msg {
			font-size: 0.7rem;
		}

		.signin-button {
			&:disabled {
				background-color: $black;
				cursor: default;
				border: 1px solid $black;

				&:hover {
					color: $black;
				}
			}
		}
	}

	//mixins
	@mixin placeholder-default {
		opacity: 1;
		color: $black;
		transition: 1s ease;

		.textInput--error & {
			color: $error-red;
		}
	}

	@mixin placeholder-hover {
		color: $black;
	}

	@mixin placeholder-focus {
		opacity: 0;
		transition: 300ms ease;
	}

	@mixin placeholder-disabled {
		color: $gray2;
	}

	@mixin placeholder-takeover {
		color: transparent;
	}

	@mixin labelFocus() {
		opacity: 1;
		transform: translateY(-15px);
	}

	// textInput
	.textInput {
		position: relative;
		margin-top: 15px;
		padding-bottom: 30px;

		.icon-close {
			fill: $black;
		}

		&--error {
			color: $error-red;

			.icon-close {
				fill: $error-red;
			}
		}
	}
	.textInput__message {
		.textInput--error & {
			display: block;
		}

		display: none;
		position: absolute;
		bottom: 0;

		@include sansThin;
		font-size: rem(12, 16);
		letter-spacing: em(1.2, 12);
		line-height: em(24, 12);
	}

	// field
	.textInput__field {
		width: 100%;
		border: none;
		border-bottom: 1px solid $black;
		padding: 0.5em 0;
		color: $black;
		background: #fff;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		border-radius: 0;
		-webkit-appearance: none;

		&--readOnly {
			border-bottom: 0;
			padding-bottom: 0;
		}

		// field when in focus
		&:focus {
			outline: none;
		}

		&:invalid {
			box-shadow: none;
		}

		// field when in focus and valid - filled in
		&:valid {
			padding-left: 0;
			padding-right: 25px;

			@include rtl {
				padding-left: 25px;
				padding-right: 0;
			}
		}

		// field disabled
		&--disabled {
			cursor: not-allowed;
		}

		.textInput--error & {
			color: $error-red;
			border-color: $error-red;
		}
	}

	// label
	.textInput__label {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		transition: 0.5s ease;

		@include ie {
			transition: none;
		}

		@include rtl {
			left: auto;
			right: 0;
		}

		.textInput--error & {
			color: $error-red;
		}
	}

	// label when in focus
	.textInput__field:focus + .textInput__label {
		@include labelFocus();
	}

	// label when valid (filled in)
	.textInput__field:valid + .textInput__label {
		@include labelFocus();
	}

	.textInput__field--readOnly + .textInput__label:not(.textInput__label--hidden) {
		@include labelFocus();
	}

	// placeholder
	::-webkit-input-placeholder {
		@include placeholder-default;
	}

	:-moz-placeholder {
		@include placeholder-default;
	}

	::-moz-placeholder {
		@include placeholder-default;
	}

	:-ms-input-placeholder {
		@include placeholder-default;
	}

	// placeholder hover
	.textInput__field:hover::-webkit-input-placeholder {
		@include placeholder-hover();
	}

	.textInput__field:hover:-moz-placeholder {
		@include placeholder-hover();
	}

	.textInput__field:hover::-moz-placeholder {
		@include placeholder-hover();
	}

	.textInput__field:hover:-ms-input-placeholder {
		@include placeholder-hover();
	}

	// placeholder - become hidden when in focus
	.textInput__field:focus::-webkit-input-placeholder {
		@include placeholder-focus();
	}

	.textInput__field:focus:-moz-placeholder {
		@include placeholder-focus();
	}

	.textInput__field:focus::-moz-placeholder {
		@include placeholder-focus();
	}

	.textInput__field:focus:-ms-input-placeholder {
		@include placeholder-focus();
	}

	// placeholder disabled
	.textInput__field--disabled::-webkit-input-placeholder,
	.textInput__field--disabled:hover::-webkit-input-placeholder {
		@include placeholder-disabled();
	}

	.textInput__field--disabled:-moz-placeholder,
	.textInput__field--disabled:hover:-moz-placeholder {
		@include placeholder-disabled();
	}

	.textInput__field--disabled::-moz-placeholder,
	.textInput__field--disabled:hover::-moz-placeholder {
		@include placeholder-disabled();
	}

	.textInput__field--disabled:-ms-input-placeholder,
	.textInput__field--disabled:hover:-ms-input-placeholder {
		@include placeholder-disabled();
	}

	.textInput_clear {
		position: absolute;
		bottom: 17px;
		right: 0;
		width: 15px;
		height: 15px;

		@include placeholder-focus();

		&:focus {
			opacity: 1;
		}

		@include rtl {
			right: auto;
			left: 0;
		}
	}

	.textInput__field:focus ~ .textInput_clear,
	.textInput__field:focus:valid ~ .textInput_clear,
	.textInput__field:valid ~ .textInput_clear {
		opacity: 1;
	}
}
